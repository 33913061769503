<template>
  <v-card>
    <v-toolbar dense dark color="primary">
      <v-toolbar-title><h4 class="font-weight-light">MONTHLY QUOTA REPORTS</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col
        md="12"
        cols="12"
      ></v-col>
      <v-col
        md="4"
        cols="12"
      >
        <v-select
          v-model="month_of"
          class="mx-3"
          :items="month_of_items"
          item-value="id"
          item-text="month_of"
          label="Month Of"
          required
          @change="monthly_collection_data"
          dense
        ></v-select>
      </v-col>
      <v-col
        md="4"
        cols="12"
      >
        <span class="headline">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
      </v-col>
    </v-row>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Collectors Name
            </th>
            <!--            <th class=" text-uppercase">-->
            <!--              Details-->
            <!--            </th>-->
            <th class=" text-uppercase">
              No. of Accounts
            </th>
            <th class=" text-uppercase">
              Total Quota
            </th>
            <th class=" text-uppercase">
              Total Collection
            </th>
            <th class=" text-uppercase">
              Total Due
            </th>
            <th class=" text-uppercase">
              Total Due Collected
            </th>
            <th class=" text-uppercase">
              TCP
            </th>
            <th class=" text-uppercase">
              DAP
            </th>
            <th class=" text-uppercase">
              Incentive Rate
            <th class=" text-uppercase">
              Incentives
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item2 in data"
            :key="item2.id"
          >
            <td v-if="is_prev">{{ item2.type_of_loan }}</td>
            <td v-else>{{ item2.name }}</td>
            <td>{{ item2.total_accounts }}</td>
            <td>{{ item2.total_quota }}</td>
            <td>{{ item2.total_collection }}</td>
            <td>{{ item2.total_due }}</td>
            <td>{{ item2.total_due_collected }}</td>
            <td v-if="is_prev">{{ item2.currency_weekly+' %' }}</td>
            <td v-else>{{ item2.tcp }}</td>
            <td v-if="is_prev">{{ item2.currency_halfMonth+' %' }}</td>
            <td v-else>{{ item2.dap }}</td>
            <td>{{ item2.incentive_rate }}</td>
            <td>{{ item2.incentives }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card-text>
  </v-card>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from "@mdi/js";

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        data: [],
        month_of_items: [],
        month_of: '',
        is_prev: false,
        collector_item: [],
        collector: '',
        category: '',
        category_se: '',
      }
    },
    mounted() {
      this.initialize_data()
    },
    watch: {
      branch_id: function () {
        this.initialize_data()
      },
    },
    computed: {
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('monthly_quota_reports', ['monthly_quota_report', 'previous_monthly_quota_report', 'get_available_collector_current_month', 'get_available_collector_prev_month']),
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      initialize_data() {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        this.retrieve_transaction_month(data)
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      monthly_collection_data() {
        if (this.branch_id > 0) {
          var index = this.month_of_items.map(function (x) {
            return x.id;
          }).indexOf(this.month_of)
          if (index != -1) {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('month_of', this.month_of_items[index].month_of);
            data.append('month_end', this.month_of_items[index].month_end);
            data.append('is_new_month', 0);
            data.append('type_of_loan', this.category_se);
            if (index === 0) {
              this.is_prev = false
              this.monthly_quota_report(data)
                .then(response => {
                  this.data = response.data
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              this.is_prev = true
              this.previous_monthly_quota_report(data)
                .then(response => {
                  this.data = response.data
                })
                .catch(error => {
                  console.log(error)
                })
            }
          }
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        array.push(
          [
            {text: 'COLLECTORS NAME', alignment: 'center', style: 'label'},
            {text: 'NO. OF ACCOUNTS', alignment: 'center', style: 'label'},
            {text: 'TOTAL QOUTA', alignment: 'center', style: 'label'},
            {text: 'TOTAL COLLECTION', alignment: 'center', style: 'label'},
            {text: 'TOTAL DUE', alignment: 'center', style: 'label'},
            {text: 'TOTAL DUE COLLECTED', alignment: 'center', style: 'label'},
            {text: 'TCP', alignment: 'center', style: 'label'},
            {text: 'DAP', alignment: 'center', style: 'label'},
            {text: 'IR', alignment: 'center', style: 'label'},
            {text: 'INCENTIVES', alignment: 'center', style: 'label'},
          ]
        )
        if (this.data.length > 0) {
          var is_prv = this.is_prev
          this.data.forEach(function (item) {
            array.push(
              [
                {text: is_prv ? item.type_of_loan : item.name, alignment: 'center'},
                {text: item.total_accounts, alignment: 'left'},
                {text: item.total_quota, alignment: 'left'},
                {text: item.total_collection, alignment: 'left'},
                {text: item.total_due, alignment: 'left'},
                {text: item.total_due_collected, alignment: 'left'},
                {
                  text: is_prv ? item.currency_weekly : item.tcp,
                  alignment: 'left'
                },
                {
                  text: is_prv ? item.currency_halfMonth : item.dap,
                  alignment: 'left'
                },
                {
                  text: item.incentive_rate,
                  alignment: 'left'
                },
                {
                  text: item.incentives,
                  alignment: 'left'
                },
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'MONTHLY QUOTA REPORTS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Category: ' + this.category_se, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [150, 40, 50, 50, 30, 50, 25, 25,20, 30],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
